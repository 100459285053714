import clsx from "clsx";

import Link from "next/link";
import router from "next/router";
import { useEffect } from "react";

const PrimaryButton = ({
  target,
  isFull = false,
  outline = false,
  color = null,
  darkBackground = false,
}) => {
  const handleClick = (text, url) => {
    window.dataLayer?.push({
      event: "event",
      eventProps: {
        category: "Button Click",
        action: text,
        label: url,
      },
    });
  };

  let href;

  let newTab = false;

  useEffect(() => {
    if (
      router.pathname === "/partner-portal/[slug]" &&
      target.element?.sectionHandle === "partnerPortal"
    ) {
      newTab = false;
      if (!!target.element.downloadableFiles[0]) {
        href = target.element.downloadableFiles[0].url;
        newTab = true;
      }
    } else if (router.pathname === "/partner-portal/[slug]") {
      newTab = true;
    }
  });

  return (
    <Link
      href={
        href
          ? href
          : target.element?.uri
          ? `/${target.element.uri}`
          : target.url
      }
      target={newTab ? "_blank" : target.target}
      onClick={() => handleClick(target.text, target.url)}
      className={clsx(
        "PrimaryButton",
        "flex items-center justify-center",
        "h-10 px-5",
        "rounded-sm",
        "transition-colors",
        {
          "bg-purple-01 text-white-01 hover:bg-black-01 hover:text-white-01":
            !outline && !darkBackground && color !== "black",
          "bg-purple-01 text-white-01 hover:bg-white-02 hover:text-black-01":
            !outline && darkBackground && color !== "black",
          "bg-black-01 text-white-01 hover:bg-purple-01 hover:text-white-01":
            !outline && !darkBackground && color === "black",
          "bg-black-01 text-white-01 hover:bg-white-02 hover:text-black-02":
            !outline && darkBackground && color === "black",
          "border border-purple-01 text-purple-01 hover:bg-purple-01  hover:text-white-01":
            outline,
        },
        {
          "w-full": isFull,
        }
      )}
    >
      <span className={clsx("PrimaryButton__label", "whitespace-nowrap")}>
        {target.text}
      </span>
    </Link>
  );
};

export default PrimaryButton;
