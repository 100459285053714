import IImage from "@sharedInterfaces/Image.interface";
import clsx from "clsx";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import Image from "next/image";

import { Box, Flex } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { ReactSVG } from "react-svg";
import { blockSpacing } from "../lib/getBlockSpacing";
import { getImageSizes } from "../lib/getImageSizes";

interface IProps {
  image: IImage;
  mobileImage?: IImage;
  maxWidth?: string;
  maxWidthPixels?: number;
  horizontalAlignment?: { base: string; md: string };
  columnWidth?: number | string;
  containerMaxWidth?: number | string;
  gridMode?: boolean;
  gridColumns?: number;
  isFirstContainer?: boolean;
  iconColor?: string;
  legacyBackgroundColor?: string;
  roundedImage?: boolean;
  blockPadding?: { top: string; bottom: string; left: string; right: string; }[];
  mobileBlockPadding?: { top: string; bottom: string; left: string; right: string; }[];
  blockMargin?: { top: string; bottom: string; left: string; right: string; }[];
  mobileBlockMargin?: { top: string; bottom: string; left: string; right: string; }[];
}

const PageImage = ({
  image,
	mobileImage,
	maxWidth,
	maxWidthPixels,
	horizontalAlignment = { base: "center", md: "center" },
	columnWidth,
	containerMaxWidth,
	gridMode,
	gridColumns,
	isFirstContainer,
	iconColor,
	legacyBackgroundColor,
	roundedImage = false,
	blockPadding,
	mobileBlockPadding,
	blockMargin,
	mobileBlockMargin,
}: IProps) => {
	const { imageWidth, imageLog } = getImageSizes(
		containerMaxWidth,
		columnWidth,
		gridMode,
		gridColumns,
		maxWidthPixels
	);

	const transform = {
		replace: (domNode) => {
			if (domNode.attribs && domNode.name === "svg") {
				const props = attributesToProps(domNode.attribs);
				return (
					<svg style={{ width: "100%", height: "auto" }} {...props}>
						{domToReact(domNode.children, transform)}
					</svg>
				);
			}
		},
	};
	return (
		<Flex
			className={clsx("PageImage")}
			w="100%"
			backgroundColor={legacyBackgroundColor}
			justify={horizontalAlignment}
			{...blockSpacing(
				blockPadding,
				mobileBlockPadding,
				blockMargin,
				mobileBlockMargin
			)}
		>
			<Flex
				className={clsx("PageImage__imageWrapper flex", {
					block: !mobileImage,
					"hidden md:block": !!mobileImage,
				})}
				w={image?.extension !== "svg" && "100%"}
				maxWidth={maxWidthPixels ? maxWidthPixels : maxWidth}
				color={iconColor ? iconColor : null}
				justify={horizontalAlignment}
			>
				{!iconColor && image?.extension === "svg" && (
					<>
						{image.svgContent ? (
							<>{!!image?.svgContent && parse(image.svgContent, transform)}</>
						) : (
							<ReactSVG
								loading={() => <ClipLoader />}
								beforeInjection={(svg) => {
									svg.setAttribute("style", "max-width: 100%; height: auto;");
									svg.setAttribute("shape-rendering", "geometricPrecision");
									svg.querySelectorAll("*").forEach((x) => {
										x.removeAttribute("font-family");
									});
								}}
								className={clsx("inline-flex")}
								src={
									process.env.NODE_ENV === "development"
										? "/servd-volumes/images/" + image.path
										: image.url
								}
							/>
						)}
					</>
				)}

				{iconColor && image.extension === "svg" && (
					<ReactSVG
						loading={() => <ClipLoader />}
						beforeInjection={(svg) => {
							svg.querySelectorAll("*").forEach((x) => {
								x.removeAttribute("font-family");
								iconColor &&
									!x.hasAttribute("stroke") &&
									x.setAttribute("fill", "currentColor");
								iconColor &&
									x.hasAttribute("stroke") &&
									x.setAttribute("stroke", "currentColor");
							});
						}}
						className={`icon-${image.id}`}
						wrapper="span"
						src={
							process.env.NODE_ENV === "development"
								? "/servd-volumes/images/" + image.path
								: image.url
						}
					/>
				)}

				{image?.extension !== "svg" && (
					<Image
						src={image.url}
						width={image.width}
						height={image.height}
						alt={image.title}
						sizes={imageWidth}
						priority={isFirstContainer ? true : false}
						className={clsx("PageImage__image flex", {
							"rounded-full overflow-hidden": roundedImage,
						})}
						blurDataURL={image.blur ? image.blur[0].url : null}
					/>
				)}
			</Flex>

			{!!mobileImage && (
				<Box
					className={clsx("PageImage__mobileImageWrapper")}
					display={{ base: "block", md: "none" }}
				>
					{mobileImage?.extension === "svg" && (
						<ReactSVG
							loading={() => <ClipLoader />}
							beforeInjection={(svg) => {
								svg.setAttribute("style", "max-width: 100%; height: auto;");
								svg.querySelectorAll("*").forEach((x) => {
									x.removeAttribute("font-family");
								});
							}}
							wrapper="div"
							className={clsx("inline-flex")}
							src={
								process.env.NODE_ENV === "development"
									? "/servd-volumes/images/" + mobileImage.path
									: mobileImage.url
							}
						/>
					)}

					{mobileImage?.extension !== "svg" && (
						<Image
							src={mobileImage.url}
							width={mobileImage.width}
							height={image.height}
							alt={image.title}
							priority={isFirstContainer ? true : false}
							sizes={imageWidth}
							className={clsx("PageImage__image")}
							blurDataURL={mobileImage.blur ? mobileImage.blur[0].url : null}
						/>
					)}
				</Box>
			)}
		</Flex>
	);
};

export default PageImage;
