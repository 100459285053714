import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "./tailwind.config";

const tailwind = resolveConfig(tailwindConfig);

import { extendTheme } from "@chakra-ui/react";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

const FormControl = {
  baseStyle: {
    backgroundColor: "#FFFFFF",
  },
};

const FormLabel = {
  baseStyle: {
    border: "none",
    borderRadius: 0,
    color: "#00000080",
    backgroundColor: "#FFFFFF",
    fontSize: "12px",
    lineHeight: "16px",
    m: 0,
    pl: "16px",
    pt: "7px",
  }
};

const FormError = {
  baseStyle: {
    text: {
      color: "#CB0A0A",
      fontSize: "12px",
      lineHeight: "1.5",
      m: 0,
      px: "16px",
      py: "7px",
      borderTop: "1px solid #CB0A0A",
    },
    // icon: {}
  }
}

const Input = {
  baseStyle: {
    field: {
      borderRadius: 0,
      border: "none",
      color: "#000000",
      backgroundColor: "#FFFFFF",
      h: "31px",
      pt: "2px",
      pb: "5px",
    },
    // addon: {},
    // element: {},
  },
  variants: {

    slim: {
      field: {
        h: "42px",
        pt: "7px",
        pb: "7px",
      }
    },
  },
};

const Select = {
  baseStyle: {
    field: {
      borderRadius: 0,
      color: "#000000",
      backgroundColor: "#FFFFFF",
      h: "31px",
      pt: "2px",
      pb: "5px"
    },
    icon: {
      mt: "-12px",
      fontSize: "16px",
      color: "#000000",
    },
  },
  defaultProps: { variant: "default" },
  variants: {
    default: {
      field: {
        border: "none"
      }
    }
  }
};

const Textarea = {
  baseStyle: {
    borderRadius: 0,
    border: "none",
    color: "#000000",
    backgroundColor: "#FFFFFF",
  },
};

const Checkbox = {
  baseStyle: {
    control: {
      border: "1px",
      borderRadius: 0,
      borderColor: "#000000",
    },
    // icon: {},
    // container: {},
    // label: {}
  },
  defaultProps: { variant: "default", colorScheme: "purple" },
  variants: {
    darkBackground: {
      control: {
        borderColor: "#ffffff",
        colorScheme: "white",
      },
      icon: {
        color: "#000000",
      },
    }
  },
};

const Radio = {
  baseStyle: {
    control: {
      border: "1px",
      borderColor: "#000000",
    },
    // icon: {},
    // container: {},
    // label: {}
  },
  defaultProps: { variant: "default", colorScheme: "purple" },
  variants: {
    darkBackground: {
      control: {
        borderColor: "#ffffff",
        colorScheme: "white",
      },
      icon: {
        color: "#000000",
      },
    }
  },
};

const Accordion = {
  parts: ["container", "button", "panel", "icon"],
  baseStyle: {
    root: {
      w: "100%",
      display: "block"
    },
    container: {
      borderColor: "purple-gray-03",
      _last: {
        borderColor: "purple-gray-03!important",
        mb: "0!important"
      }
    },
    button: {
      pl: 0,
      py: 3.5
    },
    panel: {
      pl: 0,
      pb: 4,
    }
  },
  variants: {
    border: {
      container: {
        border: "none!important"
      },
      button: {
        borderColor: "black-02!important",
        pl: 4,
        py: 0,
        _expanded: {
          borderLeft: "1px solid",
          pl: "15px",
        },
        _hover: {
          bg: "none",
          color: "purple-01",
        }
      },
      panel: {
        borderColor: "black-02",
        borderLeft: "1px solid",
        pl: "15px",
        pb: 0,
      }
    },
    card: {
      container: {
        px: 6.5,
      },
      button: {
        borderColor: "black-02!important",
        py: 5.5,
        pl: 4,
        _expanded: {
          borderLeft: "1px solid",
          py: 2.5,
          pl: "15px",
        },
        _hover: {
          bg: "none",
          color: "purple-01",
        }
      },
      panel: {
        borderColor: "black-02",
        borderLeft: "1px solid",
        pl: "15px",
        pb: 2,
      }
    },
  },
};

const Tabs = {
  parts: ["tablist", "tabpanels", "tabpanel", "tab"],
  defaultProps: { variant: "horizontal" },
  baseStyle: {
    root: {
      w: "full",
    },
    tab: {
      color: "black-01",
    },
    tabpanels: {
      color: "inherit"
    },
    tabpanel: {
      color: "inherit"
    }
  },
  sizes: {
    sm: {
      tab: {
        fontSize: 5,
      },
      tablist: {
        p: 4,
      },
    },
  },
  variants: {
    outline: {
      tablist: {
        border: "1px solid",
      },
    },
    horizontal: {
      tabpanel: {
        px: 0,
        bg: "white-01",
      },
      tab: {
        bg: "purple-gray-03",
        color: "white-01",
        px: 5,
        py: 3,
        mr: 2,
        cursor: "pointer",
        _selected: {
          bg: "white-01",
          color: "purple-02",
        },
        _focus: {
          boxShadow: "none",
        },
      },
    },
    vertical: {
      tabpanel: {
        px: 0,
        bg: "white-01",
        w: "full",
      },
      tab: {
        px: 0,
        py: 0,
        mt: 0,
        fontSize: "0.75rem",
        lineHeight: "1rem",
        w: "100%",
        textAlign: "left",
        _selected: {
          color: "purple-01",
          fontWeight: "500",
        },
      },
    },
  },
};

const Modal = {
  baseStyle: {
    dialog: {
      bg: "white-01",
      borderRadius: 0,
      h: "auto",
      w: "100%",
    },
    body: {
      p: 0,
      w: "100%",
    },
  },
  defaultProps: { size: "xl" },
  sizes: {
    sm: {
      dialog: {
        maxW: "max-w-screen-sm",
      },
    },
    md: {
      dialog: {
        maxW: "max-w-screen-md",
      },
    },
    lg: {
      dialog: {
        maxW: "max-w-screen-lg",
      },
    },
    xl: {
      dialog: {
        maxW: "max-w-screen-xl",
      },
    },
    "2xl": {
      dialog: {
        maxW: "max-w-screen-2xl",
      },
    },
    half: {
      dialog: {
        maxW: "max-w-screen-half",
      },
    }
  },
};

const Link = {
  baseStyle: {
    _hover: {
      textDecoration: "none",
    },
  },
  variants: {
    primary: ({ colorScheme = "purple" }) => ({
      color: `${colorScheme}.500`,
      textDecoration: "underline",
      _hover: {
        color: `${colorScheme}.400`,
        textDecoration: "underline",
      },
    }),
  },
};

const Button = {
  // The styles all buttons have in common
  baseStyle: {
    fontWeight: "normal",
    borderRadius: "0.125rem",
    whiteSpace: "normal",
    ".chakra-button__icon": {
      transitionProperty: "transform",
      transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
      transitionDuration: ".15s",
    },
    _hover: {
      ".chakra-button__icon": {
        transform: "translateX(.25rem)",
      },
    },
    _focus: {
      boxShadow: "0 0 0 2px black, 0 0 0 3px white",
    },
    py: 2,
    px: 5,
    lineHeight: 1.5,
  },
  variants: {
    solid: {
      _hover: {
        background: "black",
        color: "white",
      },
      _focus: {
        background: "black",
        color: "white",
      },
      _active: {
        background: "black",
        color: "white",
      },
    },
  },
  sizes: {
    md: {
      px: 5,
      py: 2,
      h: null,
    },
  },
};

const Heading = {
  baseStyle: {
    fontWeight: "normal",
    fontFamily: "inherit",
    w: "100%",
  },
  sizes: {
    sm: {
      fontSize: "0.875rem",
      lineHeight: 1.2,
    },
    md: {
      fontSize: "1rem",
      lineHeight: 1.2,
    },
    lg: {
      fontSize: "1.125rem",
      lineHeight: 1.2,
    },
    xl: {
      fontSize: "1.25rem",
      lineHeight: 1.2,
    },
    "2xl": {
      fontSize: "1.5rem",
      lineHeight: 1.2,
    },
    "3xl": {
      fontSize: "2rem",
      lineHeight: 1.2,
    },
    "4xl": {
      fontSize: "2.25rem",
      lineHeight: 1.2,
    },
    "title": {
      fontSize: "3.0625rem",
      lineHeight: "3.75rem"
    },
    "5xl": {
      fontSize: "3.25rem",
      lineHeight: 1.2,
    },
    "6xl": {
      fontSize: "3.75rem",
      lineHeight: 1.2,
    },
    "7xl": {
      fontSize: "4.25rem",
      lineHeight: 1.2,
    },
    "14xl": {
      fontSize: "10rem",
      lineHeight: 1.2,
    },
  },
};

const theme = extendTheme(
  {
    breakpoints: {
      sm: '641px',
      md: '769px',
      lg: '1025px',
      xl: '1241px',
      '2xl': '1401px',
      '3xl': '2101px'
    },
    fonts: {
      body: "TWK Everett",
      heading: "TWK Everett",
    },
    colors: {
      ...tailwind.theme.colors,
      purple: {
        200: "#B9BAED",
        400: "#8d89f6",
        500: "#6863F2",
        600: "#483698",
      },
      purpleWarm: {
        400: "#af69a9",
        500: "#924E8C",
        600: "#692764",
      },
      white: {
        500: "#ffffff",
        600: "#f7f7f7",
      },
      black: {
        400: "#0D1418",
        500: "#000000",
      },
      red: {
        500: "#EE4852",
        600: "#CB0A0A"
      },
    },
    fontSizes: tailwind.theme.fontSize,
    radii: {
      base: "0",
    },
    space: {
      ...tailwind.theme.spacing,
      xxs: ".5rem",
      xs: "1rem",
      sm: "1.875rem",
      md: "3.75rem",
      lg: "7.5rem",
      xl: "10rem",
    },
    sizes: {
      full: "100%",
      "max-w-prose": "65ch",
      "max-w-xs": "320px",
      "max-w-sm": "384px",
      "max-w-md": "448px",
      "max-w-lg": "512px",
      "max-w-xl": "576px",
      "max-w-screen-half": "600px",
      "max-w-screen-sm": "640px",
      "max-w-screen-md": "768px",
      "max-w-screen-lg": "1024px",
      "max-w-screen-xl": "1240px",
      "max-w-screen-2xl": "1400px",
      "max-w-screen-3xl": "2100px",
      "1/2": "300px",
    },
    components: {
      Button,
      Heading,
      Link,
      Accordion,
      Tabs,
      Modal,
      FormControl,
      FormLabel,
      FormError,
      Input,
      Select,
      Textarea,
      Checkbox,
      Radio,
    },
  },
  withProse({
    baseStyle: {
      textAlign: "left",
      a: {
        textDecoration: "underline",
      },
      p: {
        mt: 0,
        ":last-child": {
          mb: 0,
        },
      },
      li: {
        pl: "0.5rem",
      },
      ul: {
        mb: "1.5rem",
        li: {
          my: "0.25rem",
          "&::marker": {
            color: "inherit !important",
          },
        },
      },
      ol: {
        mb: "1.5rem",
        li: {
          my: "0.25rem",
          listStyleType: "decimal",
          listStylePosition: "outside",
          "&::marker": {
            color: "inherit !important",
          },
        },
      },
      h1: {
        fontWeight: "normal",
        fontSize: { base: "1.5rem", md: "2.25rem" },
        lineHeight: 1.2,
      },
      h2: {
        fontWeight: "normal",
        fontSize: { base: "1.25rem", md: "2rem" },
        lineHeight: 1.2,
        mt: 0,
      },
      h3: {
        fontWeight: "normal",
        fontSize: { base: "1.15rem", md: "1.75rem" },
        lineHeight: 1.2,
      },
      h4: {
        fontWeight: "normal",
        fontSize: { base: "1rem", md: "1.5rem" },
        lineHeight: 1.2,
      },
      h5: {
        fontWeight: "normal",
      },
    },
  })
);

export default theme;
